<template>
    <div class="py-5">
      <CNavbar
        expand="lg"
        color-scheme="dark"
        class="main-navbar-background py-0"
        placement="fixed-top"
        style="position: relative;"
      >
        <!-- ^ Same background color as the Totogi documentation sidebar -->
        <!-- http://docs.api.totogi.com/graphql/#definition-InitialTemplateInstance -->
        <CContainer fluid>
          <CNavbarBrand href="#" class="py-2">
            <img
              src="@/assets/totogi-logo-white.svg"
              alt="Totogi Logo"
              style="max-width: 700px; min-height: 150px;"
              class="d-inline-block align-top"
            />
          </CNavbarBrand>
          <CNavbarBrand href="#" class="py-2">
            <img
              src="@/assets/surf-logo.png"
              alt="Totogi Logo"
              style="max-width: 700px; min-height: 150px;"
              class="d-inline-block align-top"
            />
          </CNavbarBrand>
        </CContainer>
      </CNavbar>
    </div>
    </template>
    
    <script>
    export default {
      name: 'MainNav',
      data () {
        return {
          configModalVisible: false,
          navItemClasses: 'py-1 px-3 border-bottom-4',
          carrierSelectRoutingInformation: 'ARIN',
          numberPortabilityRoutingInformation: 'ARIN'
        }
      },
      computed: {
        accountsLinkClasses () {
          return this.isAccountsPage ? this.activeLinkClasses : this.inactiveLinkClasses
        },
        activeLinkClasses () {
          return `${this.navItemClasses} active-link-border`
        },
        demoDeviceLinkClasses () {
          return this.isDeviceDemoPage ? this.activeLinkClasses : this.inactiveLinkClasses
        },
        inactiveLinkClasses () {
          return `${this.navItemClasses} inactive-link-border`
        },
        isAccountsPage () {
          return this.$router.currentRoute.value.name === 'Accounts'
        },
        isDeviceDemoPage () {
          return this.$router.currentRoute.value.name === 'DeviceDemo'
        },
        isPlansPage () {
          return this.$router.currentRoute.value.name === 'Home'
        },
        isProfilePage () {
          return this.$router.currentRoute.value.name === 'Profile'
        },
        plansLinkClasses () {
          return this.isPlansPage ? this.activeLinkClasses : this.inactiveLinkClasses
        },
        profileLinkClasses () {
          return this.isProfilePage ? this.activeLinkClasses : this.inactiveLinkClasses
        },
      },
      methods: {
        updateMncMccValues () {
          this.$store.commit('updateMcc', this.mcc, { root: true })
          this.$store.commit('updateMnc', this.mnc, { root: true })
        },
        openConfigModal () {
          this.configModalVisible = true
        },
        closeConfigModal () {
          this.configModalVisible = false
        },
        handleConfigModalClose () {
          this.configModalVisible = false
        },
        updatecarrierSelectRoutingInformation () {
          this.$store.commit('setCarrierSelectRoutingInformation', this.carrierSelectRoutingInformation)
        },
        updateNumberPortabilityRoutingInformation () {
          this.$store.commit('setNumberPortabilityRoutingInformation', this.numberPortabilityRoutingInformation)
        }
      }
    }
    </script>
    
    <style>
    .main-navbar-background {
      background-color: #001D3D;
    }
    
    .active-link-border {
      border-bottom: solid;
      border-color: #FF4F58;
    }
    
    .inactive-link-border {
      border-bottom: solid;
      border-color: rgba(0, 0, 0, 0);
    }
    </style>
    