<template>
  <CContainer fluid style="height: 100vh !important; overflow: hidden;padding: 3rem;background-color: rgb(75, 26, 117);">
    <CContainer fluid style="border-radius: 0.75rem;background-color: rgb(0, 29, 61);height: calc(100vh - 6rem) !important; overflow: hidden;box-shadow: rgba(255, 255, 255, 0.25) 0px 4px 40px 4px;">
      <div class="d-flex flex-column pb-3" style="height: 100% !important;">
        <MainNav />
        
        <div class="pt-4">
          <CCard>
            <CCardHeader><h1>Totogi is always on! Live transactions from one customer:</h1></CCardHeader>
            <CCardBody>
              <div class="py-4">
                <div ref="odometer" style="font-size: 100px;"></div>
              </div>
            </CCardBody>
          </CCard>
        </div>
        <div class="pt-4">
          <CCard>
            <CCardBody>
              <CCardTitle>
                <h2>Live transactions from Surf - services 50+ MVNOs!</h2>
              </CCardTitle>
            </CCardBody>
          </CCard>
       </div>
        <div class="pt-4"></div>
        <div class="flex-grow-1" style="position: relative;overflow: scroll;">
          <CCard style="border: rgba(0,0,0,0) !important; cursor: pointer;" @click="openDashboard">
            <img class="card-img-bottom card-img-top"
              src="@/assets/dashboard-5.png" />
          </CCard>
        </div>
      </div>
    </CContainer>
    <CToaster placement="bottom-end">
      <CToast :autohide="true" style="opacity: 1.0 !important; width: 750px !important;" :delay="5000" color="dark" v-for="toast in toasts" :key="toast.uuid" class="align-items-center text-white" visible>
        <div class="d-flex">
          <CToastBody><span style="font-size: 390%;font-style: bold;">
            <font-awesome-icon :icon="['far', 'lightbulb']" />
            {{ toast.message }}
          </span></CToastBody>
          <CToastClose class="me-2 m-auto" white />
        </div>
      </CToast>
    </CToaster>
  </CContainer>
</template>


<script>
import Odometer from 'odometer'
import MainNav from './components/MainNav.vue'
import axios from 'axios'
import { v4 as uuidv4 } from 'uuid'

export default {
  name: 'App',
  components: { MainNav },
  data () {
    return {
      tpsRaw: 0,
      odometer: null,
      toasts: [],
      toastIntervalId: null,
      toastMessages: [
        'Additional customers likely to churn identified',
        'Pricing optimizations auto-calculated',
        'Recommended plan modifications for ARPU increase identified'
      ]
    }
  },
  computed: {
    tpsDisplay: {
      get () {
        return this.tpsRaw.toLocaleString()
      },
      set (newValue) {
        this.tpsRaw = newValue
      }
    }
  },
  mounted () {
    this.initDashboard()
    this.toasts = [{
      uuid: uuidv4(),
      message: this.toastMessages[Math.floor(Math.random() * this.toastMessages.length)]
    }]
    this.toastIntervalId = window.setInterval((() => {
      this.toasts.push({
        uuid: uuidv4(),
        message: this.toastMessages[Math.floor(Math.random() * this.toastMessages.length)]
      })
    }).bind(this), 30000)
  },
  unmounted () {
    if (this.toastIntervalId) {
      window.clearInterval(this.toastIntervalId)
    }
  },
  methods: {
    async initDashboard () {
      const tpsComputer = (() => {
        this.tpsRaw += Math.floor(13 + Math.random() * 5) * 2
        this.odometer.update(this.tpsRaw)
      }).bind(this)
      console.log('Odometer', Odometer)
      this.odometer = new Odometer({
        el: this.$refs.odometer,
        value: this.tpsRaw,
        format: '(,ddd)',
        theme: 'car'
      })
      const response = await axios.post(
        'https://ockphh50i0.execute-api.us-east-1.amazonaws.com/total_transactions_base_line',
        {
          baseline_value: this.tpsRaw
        }
      )
      console.log('response', response.data)
      this.tpsRaw = response.data.baseline_value
      setInterval(tpsComputer, 2000)
    },
    openDashboard () {
      window.open('https://us-east-1.quicksight.aws.amazon.com/sn/dashboards/3fdac105-ffa2-4091-ab0b-ddb60d444b78/views/9feb5a24-f7f6-45d3-8e5b-3907bba76ee8', '_blank')
    }
  }
}
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}
.odometer-digit:last-child {
  background-image: url(data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0idXRmLTgiPz4gPHN2ZyB2ZXJzaW9uPSIxLjEiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+PGRlZnM+PGxpbmVhckdyYWRpZW50IGlkPSJncmFkIiBncmFkaWVudFVuaXRzPSJvYmplY3RCb3VuZGluZ0JveCIgeDE9IjAuNSIgeTE9IjAuMCIgeDI9IjAuNSIgeTI9IjEuMCI+PHN0b3Agb2Zmc2V0PSIwJSIgc3RvcC1jb2xvcj0iIzMzMzMzMyIvPjxzdG9wIG9mZnNldD0iNDAlIiBzdG9wLWNvbG9yPSIjMzMzMzMzIi8+PHN0b3Agb2Zmc2V0PSI2MCUiIHN0b3AtY29sb3I9IiMxMDEwMTAiLz48c3RvcCBvZmZzZXQ9IjgwJSIgc3RvcC1jb2xvcj0iIzMzMzMzMyIvPjxzdG9wIG9mZnNldD0iMTAwJSIgc3RvcC1jb2xvcj0iIzMzMzMzMyIvPjwvbGluZWFyR3JhZGllbnQ+PC9kZWZzPjxyZWN0IHg9IjAiIHk9IjAiIHdpZHRoPSIxMDAlIiBoZWlnaHQ9IjEwMCUiIGZpbGw9InVybCgjZ3JhZCkiIC8+PC9zdmc+IA==) !important;
  color: #eee0d3 !important;
}
</style>
